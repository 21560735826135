import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import PostTile from '~components/PostTile'
import Section from '~components/Section'
import Multi from '~components/Multi'

const PostGrid = ({ className, posts, title, rows }) => {

	return (
		<Wrap className={className}>
			{title && <Title><Multi>{title}</Multi></Title>}	
			{posts?.map(post => (
				<Post key={post?.slug?.current} content={post} rows={rows}/>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: 110px;
	padding-bottom: 63px;
`
const Title = styled.h1`
	grid-column: span 12;
	margin-bottom: 77px;
`
const Post = styled(PostTile)`
	grid-column: ${ props => `span ${12 / props.rows}`};
	${tablet}{
		grid-column: span 6;
	}
	${mobile}{
		grid-column: span 12;
	}
`

PostGrid.propTypes = {
	className: PropTypes.string,
	posts: PropTypes.array,
	title: PropTypes.string,
	rows: PropTypes.number,
}

export default PostGrid