import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import { useFlexSearch } from 'react-use-flexsearch'
import { useQueryParam, StringParam } from 'use-query-params'
import Section from '~components/Section'
import PostGrid from '~components/PostGrid'

const Search = ({ data }) => {

	const [term, setTerm] = useQueryParam('q', StringParam)
	const { index, store } = data.localSearchPages

	const results = useFlexSearch(term, index, store, {
		language: 'en',
		tokenize: 'full'
	})

	return (
		results &&
		<Wrap>
			<Header>
				<Heading>{results?.length} result{results?.length > 1 && 's'} for "{term}"</Heading>
			</Header>
			<Results>
				{results?.length === 0 &&
					<Empty>Sorry, no results.</Empty>
				} 
			</Results>
			<PostGrid posts={results} rows={4} />
			<DataViewer data={results} name='results' />	
		</Wrap>
	)
}

const Wrap = styled.div`

`
const Header = styled(Section)`
	grid-column: span 12;
	padding-top: 248px;
	padding-bottom: 50px;
	background-color: var(--beige);
	${mobile}{
		padding-top: 189px;
		padding-bottom: 31px;
	}
`
const Heading = styled.h1`
	grid-column: span 12;
`
const Results = styled(Section)`
	
`
const Empty = styled.div`
	grid-column: span 12;
	padding: 50px 0;
	${mobile}{
		padding: 31px 0;
	}
`
export const query = graphql`
	{
		localSearchPages {
			store
			index
		}
	}
`


Search.propTypes = {
	data: PropTypes.string
}

export default Search